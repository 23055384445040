import React from "react"
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

const AboutMePage = ({
    data: {
        site
    },
}) => {
    return (
        <Layout>
            <Helmet>
                <title>About Me — {site.siteMetadata.title}</title>
                <meta name="description" content={"A brief overview"} />
            </Helmet>
            <div className="flex">
                <div className="md:max-w-1xl sm:mx-auto">
                    <div className="relative px-12 bg-white shadow-lg rounded-2xl p-10">
                        <h1 className="flex justify-center text-3xl text-black font-bold py-5">About Me</h1>
                        <div className="max-w-md mx-auto">
                            <div className="relative shadow mx-auto h-40 w-40 border-white rounded-full overflow-hidden border-4">
                                <img className="object-cover w-full h-full" src="/assets/me.jpeg" />
                            </div>
                            <h1 className="text-lg text-center text-black font-semibold py-2">
                                Imdad Ahad
                            </h1>
                            <div className="flex flex-col justify-center py-2 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                <p><b>What I mostly do</b></p>
                                <p>Cofounder & CTO @ <a className="text-red-500" href="https://scoodle.co.uk">Scoodle</a></p>
                                <p><b>In my free time</b></p>
                                <p>I'm a passionate content creator teaching programming with fun and interesting projects.</p>
                                <p>My content has helped more than 3M students 🙌</p>
                                <p><b>Where you can find me</b></p>
                                <div className="flex flex-row space-x-4 py-2">
                                    <a href="https://www.tiktok.com/@imdadcodes">
                                        <img className="h-6 w-6" src="/assets/social/tiktok.svg" />
                                    </a>
                                    <a href="https://www.instagram.com/imdadcodes">
                                        <img className="h-6 w-6" fill="#E4405F" src="/assets/social/instagram.svg" />
                                    </a>
                                    <a href="https://www.youtube.com/c/ImdadAhad">
                                        <img className="h-6 w-6" fill="#E4405F" src="/assets/social/youtube.svg" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/imdadahad">
                                        <img className="h-6 w-6" fill="#E4405F" src="/assets/social/linkedin.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default AboutMePage
export const pageQuery = graphql`
  query AboutMePageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`